import React, {ComponentProps} from 'react';
import {LanguageIcon} from '@heroicons/react/24/outline';
import {Lang} from '../Model/lang';

function Languages(props: ComponentProps<any>) {
    return (<div className="prose">
        <h3><LanguageIcon className="icon"/> Langues</h3>
        <ul>
            {props.langs.map((l: Lang) => <li key={l.lang}>{l.lang} : {l.level}</li>)}
        </ul>
    </div>);
}

export default Languages;
