export enum TagCategory {
    FRONT = 'FRONT',
    BACK = 'BACK',
    MOBILE = 'MOBILE',
    DB = 'DB',
    OTHER = 'OTHER'
}

export class Tag {
    name: string = ""
    workThing: Boolean = true
    category!: TagCategory
}

export function getTagColor(t: Tag) {
    switch (t.category) {
        case TagCategory.FRONT:
            return "bg-rose-500"
        case TagCategory.BACK:
            return "bg-indigo-600"
        case TagCategory.MOBILE:
            return "bg-yellow-500"
        case TagCategory.DB:
            return "bg-emerald-500"
        case TagCategory.OTHER:
            return "bg-stone-300"
    }
};

export function getTagTitle(cat: string) {
    switch (cat) {
        case TagCategory.FRONT:
            return 'Developpement Front'
        case TagCategory.BACK:
            return 'Developpement Back'
        case TagCategory.MOBILE:
            return 'Developpement Mobile'
        case TagCategory.DB:
            return 'Bases de données'
        case TagCategory.OTHER:
            return 'Autre'
    }
};

