import React, {ComponentProps} from 'react';
import {BeakerIcon} from '@heroicons/react/24/outline';
import {SideProject} from '../Model/side-project';

function SideProjects(props: ComponentProps<any>) {
    return (<div className="prose">
        <h3><BeakerIcon className="icon"/> Projets personnels</h3>
        {Object.entries(props.projs).map(projectsOfCat => {
            const cat = projectsOfCat[0];
            const projs = projectsOfCat[1] as SideProject[];
            return (<div  key={cat}>
                <h4>{cat}</h4>
                <ul>
                    {projs.map((p: SideProject) => {
                        return (<li  key={p.description}>
                            {p.title ? <div><a href={p.url}>{p.title}</a> {p.description}</div> : <div>{p.description}</div>}
                        </li>)
                    })}
                </ul>
            </div>)
        })}
    </div>);
}

export default SideProjects;
