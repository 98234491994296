import React, {ComponentProps} from 'react';
import {FaceSmileIcon} from '@heroicons/react/24/outline';
import {Interest} from '../Model/interests';

function Interests(props: ComponentProps<any>) {
    return (<div className="prose">
        <h3><FaceSmileIcon className="icon"/> Centre d'intérets</h3>
        <ul>
            {props.interests.map((i: Interest) => <li key={i.theme}><strong>{i.theme}</strong> : {i.description}</li>)}
        </ul>
    </div>);
}

export default Interests;
