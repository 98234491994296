import React, {ComponentProps} from 'react';

function Job(props: ComponentProps<any>) {
    return (<div className="mb-5">
        <div className="flex justify-between w-full"><h4 className="m-0">{props.jobTitle}</h4>
            <div>{props.start} - {props.end}</div>
        </div>
        <h5>{props.company}</h5>
        <ul>
            {props.tasks.split("|").map((task: string) => <li key={task}>{task}</li>)}
        </ul>
    </div>);
}

export default Job;
