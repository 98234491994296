import React, {ComponentProps} from 'react';
import {BriefcaseIcon} from '@heroicons/react/24/outline';
import Job from './Job';
import {JobAtCompany} from '../Model/job-at-company';

function Jobs(props: ComponentProps<any>) {
    return (<div className="prose max-w-none">
        <h3><BriefcaseIcon className="icon"/> Expériences professionnelles</h3>
        {props.jobs.map((j: JobAtCompany) => <Job jobTitle={j.jobTitle} start={j.start} end={j.end} company={j.company} tasks={j.tasks} key={j.start}></Job>)}

    </div>);
}

export default Jobs;
