import React, {ComponentProps} from 'react';
import {CheckBadgeIcon} from '@heroicons/react/24/outline';
import {getTagColor, getTagTitle, Tag} from '../Model/tag';
import {groupBy, map} from 'lodash';

function Skills(props: ComponentProps<any>) {
    const tagsByCat = groupBy(props.tags, 'category')
    return (<div className="prose">
        <h3><CheckBadgeIcon className="icon"/> Compétences</h3>
        <div className="flex flex-wrap justify-between">
            {map(tagsByCat, (tags, cat) => {
                return (<div key={cat}>
                    <h4 className="m-0 mb-1.5">{getTagTitle(cat)}</h4>
                    <div>
                        {tags.map((t: Tag) => <button key={t.name} className={"tag " + getTagColor(t)}>{t.name}</button>)}
                    </div>
                </div>)
            })}
        </div>
    </div>);
}

export default Skills;
