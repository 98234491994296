import {EducationYear} from './education';
import {SideProject} from './side-project';
import {Interest} from './interests';
import {Profile} from './profile';
import {Tag} from './tag';
import {Lang} from './lang';
import {JobAtCompany} from './job-at-company';

export class Me {
    profile!: Profile
    interests!: Interest[]
    jobs!: JobAtCompany[]
    sideProjects!: Map<string, SideProject[]>
    educationYears!: EducationYear[]
    languages!: Lang[]
    tags!: Tag[]
}
