import React, {ComponentProps} from 'react';
import {AcademicCapIcon} from '@heroicons/react/24/outline';
import {EducationYear} from '../Model/education';

function Education(props: ComponentProps<any>) {
    return (<div className="prose max-w-none">
        <h3><AcademicCapIcon className="icon"/> Formations</h3>
        {props.education.map((e: EducationYear) => <div key={e.endDate} className="mb-5">
            <div className="flex justify-between w-full"><h4 className="m-0">{e.school}</h4>
                <div>{e.endDate}</div>
            </div>
            <div>{e.name}</div>
        </div>)}
    </div>);
}

export default Education;
