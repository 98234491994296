import React, {ComponentProps} from 'react';
import {CodeBracketIcon, EnvelopeIcon, PhoneIcon, UserPlusIcon} from '@heroicons/react/24/outline';
import {getTagColor, Tag} from '../Model/tag';
import {Profile} from '../Model/profile';

function Header(props: ComponentProps<any>) {
    const tags = props.tags.filter((t: Tag) => t.workThing)
    const profile: Profile = props.profile
    return (<div
        className="card flex flex-wrap-reverse">
        <div className="basis-1/4">
            <img className="w-60 rounded-full shadow-lg lg:divide-x divide-gray-600" src={profile.picture} alt={profile.name}
            />
        </div>
        <div className="basis-2/4 prose p-3 prose-sm grow">
            <h1>{profile.name}</h1>
            <h2>{profile.title}</h2>
            <blockquote>{profile.quote}</blockquote>
            <div className="prose flex flex-wrap justify-between"> {/* Tags */}
                {tags.map((t: Tag) => <button key={t.name} className={"tag " + getTagColor(t)}>{t.name}</button>)}
            </div>
        </div>
        <div className="basis-1/4 lg:divide-y divide-gray-400 prose p-3 grow">
            <div className="text-center lg:text-left">
                <h3 className="mt-2 mb-1">Me contacter</h3>
                <div><EnvelopeIcon className="icon"/><a className="" href={"mailto:" + profile.email}>Par
                    mail</a></div>
                <div><PhoneIcon className="icon"/><a className="" href={"tel:" + profile.phone}>Par
                    téléphone</a></div>
                <h3 className="mt-2 mb-1">Me connaitre</h3>
                <div><UserPlusIcon className="icon"/><a className=""
                                                                         href={profile.linkedin}

                                                                         target="_blank" rel="noreferrer">Mon linkedin</a></div>
                <div><CodeBracketIcon className="icon"/><a className=""

                                                                            href={profile.source} target="_blank" rel="noreferrer">Mon
                    code</a></div>
            </div>
        </div>
    </div>);
}

export default Header;
